<route>
{
  "name": "professional-details",
  "meta": {
    "title": "Filling professional details"
  }
}
</route>

<template>
  <div class="professional-details">
    <filling-header icon="2" title="Professional details" step="2/3" :done="isStepDone" />

    <ui-form
      :model="values"
      :rules="rules"
      :hideRequiredMark="true"
      @form="form = $event"
      @submit.prevent="handleSubmit"
    >
      <ui-form-item label="Choose your profession" prop="professionSwitch">
        <ui-radio-group
          :options="professions"
          v-model="values.currentProfession"
          @change="handleProfessionSwitch"
        ></ui-radio-group>
      </ui-form-item>

      <template v-if="values.hasAllowedProfession">
        <ui-form-item label="Total years of medical experience" prop="countExperience">
          <ui-number-input v-model="values.countExperience" placeholder="ex. 3 years"></ui-number-input>
        </ui-form-item>

        <ui-form-item label="What hospital departments you have experience in?" prop="departments">
          <ui-tag-input v-model="values.departments" :options="hospitalDepartments"></ui-tag-input>
        </ui-form-item>
      </template>

      <template v-if="values.hasAllowedProfession === false">
        <ui-form-item label="What is your profession?" prop="currentProfession" key="currentProfession">
          <ui-text-input v-model="values.currentProfession" placeholder="ex. lawyer"></ui-text-input>
        </ui-form-item>
      </template>
    </ui-form>

    <div class="flex-grow"></div>

    <filling-footer @prev="prev" @next="handleSubmit"></filling-footer>
  </div>
</template>

<script>
import { paramsForServer } from 'feathers-hooks-common';
import promiseValidateFields from '../../utils/promiseValidateFields';
import FillingHeader from '../../components/filling/FillingHeader';
import UiForm from '../../components/ui/UiForm';
import UiFormItem from '../../components/ui/UiFormItem';
import UiRadioGroup from '../../components/ui/UiRadioGroup';
import UiTextInput from '../../components/ui/UiTextInput';
import UiNumberInput from '../../components/ui/UiNumberInput';
import FillingFooter from '../../components/filling/FillingFooter';
import UiTagInput from '../../components/ui/UiTagInput';
import mapFields from '../../utils/mapFields';
import config from '../../config';

const OTHER_OPTION_LABEL = 'Other';

export default {
  name: 'professional-details',
  components: {
    UiTagInput,
    FillingFooter,
    FillingHeader,
    UiForm,
    UiFormItem,
    UiNumberInput,
    UiTextInput,
    UiRadioGroup
  },
  data() {
    const contractExpireMaxDate = new Date();
    contractExpireMaxDate.setFullYear(contractExpireMaxDate.getFullYear() + 30);

    return {
      uploadProgress: 0,
      form: {},
      values: {
        hasAllowedProfession: null,
        hasEmploymentContract: null,
        сontractExpire: undefined,
        countExperience: undefined,
        departments: [],
        currentProfession: undefined
      },
      rules: {
        professionSwitch: [
          {
            validator: (rule, value, callback) => {
              if (typeof this.values.currentProfession === 'string') {
                callback();
              } else {
                callback(new Error('Please, choose you profession'));
              }
            }
          }
        ],
        countExperience: [
          {
            required: true,
            message: 'Please, enter total years of your medical experience'
          }
        ],
        departments: [
          {
            required: true,
            message: 'Please, select Hospital Departments you have experience in'
          }
        ],
        currentProfession: [
          {
            validator: (rule, value, callback) => {
              if (!this.hasAllowedProfession) {
                if (!value) {
                  callback(new Error('Please, enter you current profession'));
                  return;
                }
              }
              callback();
            }
          }
        ]
      },
      professions: [
        { value: 'nurse', label: 'Nurse' },
        { value: 'midwife', label: 'Midwife' },
        { value: '', label: OTHER_OPTION_LABEL }
      ],
      hospitalDepartments: [],
      isStepDone: false,
      backendUrl: config.backendUrl
    };
  },
  computed: {
    ...mapFields({
      student: 'auth.user.student'
    })
  },

  async mounted() {
    try {
      const { data } = await this.$store.dispatch(
        'students-details/find',
        paramsForServer({
          query: {
            studentId: this.student.id
          }
        })
      );

      if (data.length) {
        const values = data[0];
        console.log('Preload PD:', values);

        // Fix for 3 options in profession
        if (!values.hasAllowedProfession) {
          this.professions.find((p) => p.label === OTHER_OPTION_LABEL).value = values.currentProfession;
        }

        this.values = values;
        this.isStepDone = true;
      }
    } catch (err) {
      this.$notify.parseFeathersErrors(err);
    }
    this.preloadHospitalDepartments();
  },
  methods: {
    prev() {
      this.$router.push({ path: '/filling/basic-info' });
    },
    handleProfessionSwitch(event) {
      this.values.hasAllowedProfession = ['nurse', 'midwife'].includes(event.target.value);
    },
    async handleSubmit() {
      try {
        await promiseValidateFields(this.form.validate);

        const values = { ...this.values };
        console.log('Values', values);

        let ymProfession = null;
        if (this.isRegisteredNurse) {
          ymProfession = 'Nurse';
        } else {
          ymProfession = this.currentProfession;
        }
        global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'userParams', {
          profession: ymProfession
        });

        if (!this.isStepDone) {
          await this.$store.dispatch('students-details/create', values);
        } else {
          await this.$store.dispatch('students-details/patch', [values.id, values]);
        }
        global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'application_finish_professional_details');
        this.$router.push({ name: 'personal-photo' });
      } catch (error) {
        this.$notify.parseFeathersErrors(error);
      }
    },
    async preloadHospitalDepartments() {
      try {
        const { data } = await this.$store.dispatch('medical-departments/find', {
          query: {
            $limit: 300
          }
        });
        this.hospitalDepartments = data;
      } catch (err) {
        this.$notify.parseFeathersErrors(err);
      }
    }
  }
};
</script>

<style scoped>
.professional-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex-grow {
  flex-grow: 1;
}
.contract-expire {
  min-height: 121px;
}
</style>
