<template>
  <a-input
    size="large"
    class="ui_number_input"
    inputmode="numeric"
    v-bind="{ ...$attrs, ...$props }"
    v-on="listeners"
  />
</template>

<script>
  export default {
    name: 'UiNumberInput',
    computed: {
      listeners() {
        const { input, change, ...listeners } = this.$listeners;
        const handleInput = (event) => {
          const { target } = event;
          target.value = target.value.replace(',', '.').replace(/[^\d.]/g, '');
          this.$emit('input', target.value);
        };
        const handleChange = (event) => {
          const { target } = event;
          target.value = target.value.replace(',', '.').replace(/[^\d.]/g, '');
          this.$emit('input', target.value);
          this.$emit('change', target.value);
        };
        return { input: handleInput, change: handleChange, ...listeners };
      }
    },
  }
</script>

<style lang="scss">
  .ui_number_input {
    width: 100%;
    height: 48px;
    input {
      height: 48px;
    }
    /*.ant-input-number-handler-wrap {*/
      /*display: none;*/
    /*}*/
  }
</style>
