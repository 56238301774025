<template>
  <a-select
    mode="multiple"
    class="ui_tag_input"
    :value="value && value.map((e) => e.name)"
    @change="handleChange"
  >
    <a-select-option
      v-for="option in options"
      :key="option.id"
      :value="option.name"
    >
      {{ option.name }}
    </a-select-option>
  </a-select>
</template>

<script>
  export default {
    name: 'UiTagInput',
    props: {
      value: {
        type: Array,
      },
      options: {
        type: Array,
      },
    },
    computed: {
      props() {
        const { options, ...props } = this.$props;
        return props;
      }
    },
    methods: {
      handleChange(value) {
        const obj = value.map((elem) => this.options.find((option) => option.name === elem))
        console.log('selected:', obj);
        this.$emit('input', obj);
        this.$emit('change', obj);
      },
    },
  }
</script>

<style lang="scss">
  .ui_tag_input {
    .ant-select-selection {
      min-height: 48px;
      display: flex;
      align-items: center;
      padding-bottom: 6px;
    }
  }
</style>
